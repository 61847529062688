<template>
  <el-form class="editor-expression" label-position="top">
    <div class="editor-expression__label">
      {{ localLabel }}
    </div>

    <div class="editor-expression__container">
      <el-form-item :label="$t('interface_editor.type')">
        <el-select v-model="localValue.type" placeholder="Select type">
          <el-option
            v-for="item in types"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-if="localValue.type === 'expression'" :label="$t('interface_editor.conditions')">
        <ExpressionBuilder
          v-model="localValue.expressions"
          :operators="operators"
          :value-types="valueTypes"
          :child-expanded="true"
        ></ExpressionBuilder>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import Vue from 'vue'
import ExpressionBuilder from './ExpressionBuilder'
import { EditorExpressionMixin } from './EditorExpressionMixin'

export default Vue.extend({
  name: 'EditorExpressionForm',

  mixins: [EditorExpressionMixin],

  components: {
    ExpressionBuilder
  }
})
</script>

<style lang="scss">
.expression-form {
  display: block;
  height: 40vh;
  padding: 10px;
}

.editor-expression {
  .el-dropdown-link {
    cursor: pointer;
  }
}

.editor-expression__label {
  font-weight: 500;
}

.editor-expression__label, .editor-expression__description {
  line-height: 28px;
}

.editor-expression__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
